let selfOnboardingForm = () => {
  const mainCurrencySelect = document.querySelector('#js-currency .price-currency')
  const mainCurrencyErrorText = document.querySelector('.currency-error')
  const enableMulticurrencyButton = document.querySelector('#js-add-multicurrency a')
  const addAnotherCurrencyButton = document.querySelector('#js-add-currency a')

  const currenciesList = {
    usd: false, aud: true, cad: true, eur: true, gbp: true, jpy: true,
  }

  const addAvailableOptionsToSelect = (select) => {
    const availableOptions = Object.keys(currenciesList).filter((key) => currenciesList[key])

    const alreadyAddedOptions = Array.from(select.options).map((option) => option.value)

    availableOptions.forEach((option) => {
      if (alreadyAddedOptions.includes(option)) { return }
      const newOption = document.createElement('option')
      newOption.value = option
      newOption.text = option.toUpperCase()
      select.add(newOption)
    })
  }

  const syncTemplate = () => {
    const selectTemplate = document.querySelector('#js-additional-currency-template .price-currency')
    if(selectTemplate) {
      selectTemplate.innerText = ''
      addAvailableOptionsToSelect(selectTemplate)
    }
  }

  const syncSelect = (select) => {
    const blockedOptions = Object.keys(currenciesList).filter((key) => !currenciesList[key])
    const selectedValue = select[select.selectedIndex].value
    Array.from(select.options).forEach((option) => {
      if (option.value !== selectedValue && blockedOptions.includes(option.value)) { option.remove() }
    })

    addAvailableOptionsToSelect(select)
  }

  const syncCurrencies = () => {
    // Sync object
    Object.keys(currenciesList).forEach((key) => { currenciesList[key] = true })
    if (mainCurrencySelect)
      currenciesList[mainCurrencySelect.value] = false

    // Sync currenciesList with additional currencies
    document.querySelectorAll('#js-additional-currency select').forEach((select) => {
      currenciesList[select[select.selectedIndex].value] = false
    })

    // Sync template
    syncTemplate()

    // Sync primary currency
    if (mainCurrencySelect)
      syncSelect(mainCurrencySelect)

    // Sync additional currencies
    document.querySelectorAll('#js-additional-currency select').forEach((select) => {
      syncSelect(select)
    })

    const addCurrency = document.querySelector('#js-add-currency')

    if (Object.keys(currenciesList).filter((key) => currenciesList[key]).length) {
      addCurrency && addCurrency.classList.remove('d-none')
    } else {
      addCurrency && addCurrency.classList.add('d-none')
    }
  }

  const additionalCurrenciesCount = () => (
    document.querySelectorAll('.additional-currencies-wrapper #js-additional-currency').length
  )

  const updateMainCurrency = (value) => {
    document.querySelectorAll('.additional-currencies-wrapper .currency .input-group-text').forEach((currency) => {
      const currencyBlock = currency
      currencyBlock.innerText = `1${value.toUpperCase()} =`
    })
  }

  const createAdditionalCurrenciesBlock = () => {
    syncTemplate()
    const newAdditionalCurrency = document.querySelector('#js-additional-currency-template').cloneNode(true)
    newAdditionalCurrency.classList.remove('d-none')

    newAdditionalCurrency.querySelector('.price-currency').setAttribute('name', 'deal_setup[additional_prices][][price_currency]')
    newAdditionalCurrency.querySelector('.price-amount').setAttribute('name', 'deal_setup[additional_prices][][price_amount]')
    newAdditionalCurrency.setAttribute('id', 'js-additional-currency')
    document.querySelector('.additional-currencies-wrapper').appendChild(newAdditionalCurrency)

    const select = newAdditionalCurrency.querySelector('.additional-currency-select')

    newAdditionalCurrency.querySelector('.additional-currency-select')
      .closest('.currency').querySelector('.currency-name').innerHTML = select.value.toUpperCase()

    setRemoveButtonClickHandler(newAdditionalCurrency)

    setExchangeRateCurrencyOnChangeHandler(select)

    updateMainCurrency(mainCurrencySelect.value)
    syncCurrencies()
  }

  const changeMainCurrency = () => {
    const mainCurrency = mainCurrencySelect ? mainCurrencySelect.value.toUpperCase() : ''
    const currencies = ['USD']

    syncCurrencies()
    updateMainCurrency(mainCurrency)
    if(mainCurrencyErrorText)
      mainCurrencyErrorText.innerText = !currencies.includes(mainCurrency)
        ? 'In order to use online payment methods, please select "USD" as the currency.'
        : ''
  }

  changeMainCurrency()

  const enableMulticurrency = (evt) => {
    evt.preventDefault()
    // Hide create block button
    document.querySelector('#js-add-multicurrency').classList.add('d-none')

    document.querySelector('.additional-currencies').classList.remove('d-none')
    createAdditionalCurrenciesBlock()
  }

  const setHandlersToExistingExchangeRates = (exchangeRate) => {
    setRemoveButtonClickHandler(exchangeRate)
    setExchangeRateCurrencyOnChangeHandler(exchangeRate.querySelector('.additional-currency-select'))
  }

  const setRemoveButtonClickHandler = (exchangeRate) => {
    exchangeRate.querySelector('.remove-currency').addEventListener('click', (evt) => {
      evt.preventDefault()
      evt.target.closest('#js-additional-currency').remove()
      syncCurrencies()
      if (!additionalCurrenciesCount()) {
        document.querySelector('.additional-currencies').classList.add('d-none')
        document.querySelector('#js-add-multicurrency').classList.remove('d-none')
      }
    })
  }

  const setExchangeRateCurrencyOnChangeHandler = (additionalCurrencySelect) => {
    additionalCurrencySelect.addEventListener('change', () => {
      syncCurrencies()
      additionalCurrencySelect.closest('.currency').querySelector('.currency-name').innerHTML = additionalCurrencySelect.value.toUpperCase()
    })
  }

  if (document.dealSetupForm) {
    mainCurrencySelect.addEventListener('change', changeMainCurrency)
    enableMulticurrencyButton.addEventListener('click', enableMulticurrency)
    addAnotherCurrencyButton.addEventListener('click', (evt) => { evt.preventDefault(); createAdditionalCurrenciesBlock() })
    syncCurrencies()

    document.querySelectorAll('#js-additional-currency').forEach((exchangeRate) => {
      setHandlersToExistingExchangeRates(exchangeRate)
    })
  }
}

document.addEventListener('DOMContentLoaded', selfOnboardingForm);
